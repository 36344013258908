import { FC } from 'react';

import classes from './Footer.module.scss';

const Footer: FC = () => {
  return (
    <footer className={classes.footerBox}>
      <p>
        <b>이웃삼촌</b>
        <br />
        주식회사 굿엉클스 | 대표자 : 박근철
        <br />
        주소: 서울특별시 송파구 송파대로28길20, 313호
        <br />
        사업자등록번호: 175-88-02580
        <br />
        통신판매업 신고번호: 2022-서울송파-0432
      </p>
    </footer>
  );
};

export default Footer;
