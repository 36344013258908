import { CommerceConf } from '@gooduncles/gu-app-schema';
import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import { observeCommerceConf } from 'src/lib/3/firebase-short-cut';

const initCommerceConf: CommerceConf = {
  open: false,
  openAt: 6,
  closeAt: 23,
  deliveryDateRules: [1, 1, 1, 1, 1, 1, 2],
  closed: {
    isClosed: false,
    msgClosed: '죄송합니다. 현재는 주문이 불가능합니다.',
  },
  deliveryFee: 3000,
  minAmountForFreeDelivery: 50000,
  notice: null,
};

interface AppContext {
  showLoading: boolean;
  setShowLoading: (show: boolean) => void;
  commerceConf: CommerceConf;
}

const appContext = createContext<AppContext | null>(null);

const useProvideApp = () => {
  const [commerceConf, setCommerceConf] = useState<CommerceConf>(initCommerceConf);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const subscription = observeCommerceConf().subscribe((conf) => {
      setCommerceConf(conf);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    showLoading,
    setShowLoading,
    commerceConf,
  };
};

export const AppProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const value = useProvideApp();
  return <appContext.Provider value={value}>{children}</appContext.Provider>;
};

export const useApp = () => {
  const context = useContext(appContext);
  if (context == null) {
    throw new Error('Out of context: appProvider is not set');
  }
  return context;
};
